import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.updateFileNames();
    this.updateBrowseText();
  }

  updateFileNames() {
    this.element.addEventListener('change', (event) => {
      const files = [];
      for (let i = 0; i < event.target.files.length; i++) {
        files.push(event.target.files[i].name);
      }
      event.target.nextElementSibling.innerHTML = files.join(', ');
    });
  }

  updateBrowseText() {
    this.element.nextElementSibling.dataset.browse = '参照';
  }
}
