import { Controller } from "@hotwired/stimulus"

const ADMIN_COMPANY_ID = '1';

export default class extends Controller {
  connect() {
    this.initializeForm();
  }

  initializeForm() {
    const el = this.element;
    if (el.id !== 'recruiting-companies-form') return;

    this.admins = el.querySelector('.admins');
    this.users = el.querySelector('.users');
    this.agentCompanyId = el.querySelector('#recruiting_company_agent_company_id');
    this.usersSelect = el.querySelector('#users-select');

    this.admins.style.display = 'none';
    this.admins.disabled = true;
    this.users.style.display = 'none';
    this.users.disabled = true;

    // validation error / edit
    const agentCompanyId = this.agentCompanyId.value;
    if (agentCompanyId) {
      this.authorableUserList(agentCompanyId);
    }

    // 紹介会社変更時
    this.agentCompanyId.addEventListener("change", (event) => {
      this.authorableUserList(event.target.value);
    });
  }

  authorableUserList(agentCompanyId) {
    if (agentCompanyId === ADMIN_COMPANY_ID) {
      this.admins.style.display = 'block';
      this.admins.disabled = false;
      this.users.style.display = 'none';
      this.users.disabled = true;
    } else {
      this.admins.style.display = 'none';
      this.admins.disabled = true;
      this.users.style.display = 'block';
      this.users.disabled = false;
      this.usersSelect.innerHTML = '';
      fetch(`/api/users?agent_company_id=${agentCompanyId}`)
        .then(response => response.json())
        .then(data => {
          data.forEach(user => {
            const option = document.createElement('option');
            option.value = user.id;
            option.text = user.title;
            this.usersSelect.add(option);
          });
        });
    }
  }
}
