import { Controller } from "@hotwired/stimulus";
import autoComplete from "@tarekraafat/autocomplete.js";
require('jquery-jpostal-ja');

export default class extends Controller {
  static targets = ["status", "closeReason"];

  connect() {
    this.setupAddressAutofill();
    this.setupSchoolAutocomplete();
    this.toggleCloseReason();
  }

  setupAddressAutofill() {
    $("#candidate_postcode").jpostal({
      postcode: ["#candidate_postcode"],
      address: {
        "#candidate_residential_area_id": "%3",
        "#candidate_address_city": "%4%5"
      }
    });
  }

  setupSchoolAutocomplete() {
    const autoCompleteJS = new autoComplete({
      selector: '#schools',
      placeHolder: "XXXX大学",
      data: {
        src: schools
      },
      resultItem: {
        highlight: true,
      },
    });

    autoCompleteJS.input.addEventListener("selection", function (e) {
      document.querySelector("#schools").value = e.detail.selection.value;
    });
  }

  toggleCloseReason() {
    this.closeReasonTarget.style.display = this.statusTarget.value === 'false' ? 'block' : 'none';
  }
}
