import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { candidateGraduationOnId: String, recruitmentGraduationOnId: String }

  checkGraduationOn(e) {
    e.preventDefault();

    if (this.candidateGraduationOnIdValue === this.recruitmentGraduationOnIdValue) {
      e.target.form.requestSubmit();
      return true;
    } else {
      if (confirm('候補者の卒業年度と求人の卒業年度が違います。このまま登録しますか？')) {
        e.target.form.requestSubmit();
        return true;
      } else {
        return false;
      }
    }
  }
}
