import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.checkAll = this.element.querySelector('#check-all');
    if (this.checkAll) {
      this.checkAll.addEventListener('click', this.handleCheckAll.bind(this));
    }
  }

  disconnect() {
    // cleanup if necessary
  }

  handleCheckAll() {
    const checkboxes = this.element.querySelectorAll('.form-check-input');
    checkboxes.forEach(checkbox => {
      checkbox.checked = this.checkAll.checked;
    });
  }
}
