import { Controller } from "@hotwired/stimulus"
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = ["receiverableGids"]

  validateReceiverableOnSubmit(event) {
    const receiverableGidsValue = this.receiverableGidsTarget.value;
    if (receiverableGidsValue.length === 0) {
      event.preventDefault();
      Swal.fire({
        title: '送信先が選択されていません',
        html: "メッセージは誰にも読まれない可能性があります。<br>送信先が不明な場合は<br>担当CAや担当RAを指定してください。<br>このまま送信しますか？",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'はい',
        denyButtonText: 'いいえ',
        cancelButtonText: 'キャンセル',
        customClass: {
          actions: 'my-actions',
          cancelButton: 'order-1 mr-auto',
          confirmButton: 'order-2',
          denyButton: 'order-3',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.element.submit();
        }
      })
    }
  }
}
