import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.initForm();
  }

  initForm() {
    const el = this.element;
    if (el.id !== 'recruitments-form') return;

    const recruitmentSendMail = el.querySelector('#recruitment_send_mail');
    const mailSubject = el.querySelector('.mail_subject');
    const recruitmentTalkScript = el.querySelector('#recruitment_talk_script');
    const talkScriptCounter = el.querySelector('.talk-script-counter');

    recruitmentSendMail.addEventListener('change', function () {
      if (recruitmentSendMail.checked) {
        mailSubject.classList.add('required');
      } else {
        mailSubject.classList.remove('required');
      }
    });

    recruitmentTalkScript.addEventListener('keyup', function () {
      let countNum = recruitmentTalkScript.value.length;
      let remain = 600 - countNum;
      let content =  "（600文字以内　残り " + remain + " 文字）";
      talkScriptCounter.textContent = content;
      if (remain < 0) {
        talkScriptCounter.classList.add("text-danger");
      } else {
        talkScriptCounter.classList.remove('text-danger');
      }
    });

    // Trigger the events to initialize the form
    recruitmentSendMail.dispatchEvent(new Event('change'));
    recruitmentTalkScript.dispatchEvent(new Event('keyup'));
  }

  updateRecruitingCompany(event) {
    const id = event.target.value;
    const apiPath = '/recruiting_companies/';

    fetch(apiPath + id, {
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        document.getElementById('recruitment_recruiting_plan_id').value = data['recruiting_plan_id'];
        document.getElementById('recruitment_recruiting_payment_term_id').value = data['recruiting_payment_term_id'];
        document.getElementById('recruitment_refund').value = data['refund'];
      });
  }
}
