import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["authorable", "hiddenTags", "seminarScheduleForm", "freeInput"]

  connect() {
    this.updateHiddenTags();
    this.seminarScheduleFormTargets.forEach(form => this.toggleFreeInput(form));
  }

  updateHiddenTags() {
    this.hiddenTagsTarget.innerHTML = '';
    const selectedOptions = this.authorableTarget.selectedOptions;

    for (let i = 0; i < selectedOptions.length; i++) {
      const option = selectedOptions[i];
      const input = document.createElement('input');
      input.type = 'hidden';
      input.multiple = 'multiple';
      input.value = option.dataset.authorableValue;
      input.name = `q[g][0][recruitment_authorable_of_${option.dataset.authorableType}_type_id_eq_any][]`;
      input.id = `q_g_0_recruitment_authorable_of_${option.dataset.authorableType}_type_id_eq_any`;
      this.hiddenTagsTarget.appendChild(input);
    }
  }

  toggleFreeInput(event) {
    const form = event.target;
    const freeInputIds = JSON.parse(form.dataset.freeInputIds);
    const selectedValue = parseInt(form.value);

    if (freeInputIds.includes(selectedValue)) {
      this.freeInputTarget.style.display = 'block';
    } else {
      this.freeInputTarget.style.display = 'none';
    }
  }
}
