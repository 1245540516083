import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ["sortableSelectLists"]

  connect() {
    let selection_list_id = this.sortableSelectListsTarget.dataset.selectionListId;
    new Sortable(this.sortableSelectListsTarget, {
      handle: "li.list-group-item",
      axis: 'y',
      animation: 300,
      dataIdAttr: 'data-id',
      onUpdate: this.updatePositions.bind(this, selection_list_id)
    });
  }

  updatePositions(selection_list_id, evt) {
    const listGroupItems = this.sortableSelectListsTarget.querySelectorAll('.list-group-item');
    listGroupItems.forEach((item, i) => {
      item.dataset.id = i + 1;
    });
    return fetch(`/api/selection_list_details/positions/${evt.oldIndex}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        from: evt.oldIndex,
        to: evt.newIndex,
        selection_list_id: selection_list_id
      }),
    });
  }
}
