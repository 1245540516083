import { Controller } from "@hotwired/stimulus";
import Quill from "quill";

const toolbarOptions = [
  [{ 'size': ['small', false, 'large', 'huge'] }],
  [{ 'color': [] }, { 'background': [] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ 'list': 'bullet' }],
  ['link'],
  ['clean']
];

export default class extends Controller {
  static targets = ["introduction", "description", "appeal"];

  connect() {
    this.initQuill();
  }

  initQuill() {
    this.quillIntroduction = new Quill(this.introductionTarget, {
      modules: { toolbar: toolbarOptions },
      theme: 'snow'
    });

    this.quillDescription = new Quill(this.descriptionTarget, {
      modules: { toolbar: toolbarOptions },
      theme: 'snow'
    });

    this.quillAppeal = new Quill(this.appealTarget, {
      modules: { toolbar: toolbarOptions },
      theme: 'snow'
    });

    this.quillIntroduction.on('text-change', () => {
      document.querySelector('#job_posting_introduction').value = this.quillIntroduction.root.innerHTML;
    });

    this.quillDescription.on('text-change', () => {
      document.querySelector('#job_posting_description').value = this.quillDescription.root.innerHTML;
    });

    this.quillAppeal.on('text-change', () => {
      document.querySelector('#job_posting_appeal').value = this.quillAppeal.root.innerHTML;
    });
  }
}
