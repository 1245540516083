import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ["sortableSelectionStages"]

  connect() {
    let recruitment_id = this.sortableSelectionStagesTarget.dataset.recruitmentId;
    new Sortable(this.sortableSelectionStagesTarget, {
      handle: "li.list-group-item",
      axis: 'y',
      animation: 300,
      dataIdAttr: 'data-id',
      onUpdate: this.updatePositions.bind(this, recruitment_id)
    });
  }

  updatePositions(recruitment_id, evt) {
    const listGroupItems = this.sortableSelectionStagesTarget.querySelectorAll('.list-group-item');
    listGroupItems.forEach((item, i) => {
      item.dataset.id = i + 1;
    });
    return fetch(`/api/recruitment_selection_stages/positions/${evt.oldIndex}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        from: evt.oldIndex,
        to: evt.newIndex,
        recruitment_id: recruitment_id
      }),
    });
  }
}
