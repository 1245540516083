import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    this.sortable = null;
    this.sortElements();
    if (this.element.id === 'selection_details') {
      this.initializeSelectionDetailsSortable();
    } else {
      this.initializeJobPostingSortable();
    }
  }

  disconnect() {
    if (this.sortable) {
      this.sortable.destroy();
      this.sortable = null;
    }
  }

  sortElements() {
    const sortableElements = Array.from(this.element.children);
    const linksElement = sortableElements.find(element => element.matches('.links'));
    const nestedFields = sortableElements.filter(element => element.matches('.nested-fields'));

    nestedFields.sort((a, b) => {
      const positionA = parseInt(a.querySelector('input.position').value, 10);
      const positionB = parseInt(b.querySelector('input.position').value, 10);
      return positionA - positionB;
    });

    nestedFields.forEach((nestedField) => {
      this.element.appendChild(nestedField);
    });

    if (linksElement) {
      this.element.appendChild(linksElement);
    }
  }

  initializeSelectionDetailsSortable() {
    this.sortable = new Sortable(this.element, {
      handle: ".nested-fields",
      axis: 'y',
      animation: 300,
      delay: 300,
      dataIdAttr: 'data-id',
      onSort: this.handleSort.bind(this)
    });
  }

  initializeJobPostingSortable() {
    this.sortable = new Sortable(this.element, {
      handle: '.sortable-handle',
      onSort: this.handleSort.bind(this)
    });
  }

  handleSort() {
    let t = 1;
    const nestedFields = this.element.querySelectorAll('.nested-fields');

    nestedFields.forEach((nestedField) => {
      if (nestedField.style.display === 'none') {
        // nestedField.dataset.id = 0;
        // 0で先頭にすると選考結果の整合性チェックでエラーになることがあるため999にして末尾に
        nestedField.querySelector('input.position').value = 999;
      } else {
        // nestedField.dataset.id = t;
        nestedField.querySelector('input.position').value = t;
        t++;
      }
    });
  }

  updatePositionAfterChange() {
    const parentElement = this.sortableContainerTarget;
    setTimeout(() => this.handleSort(parentElement), 500);
  }
}
