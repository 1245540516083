// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// import "./stylesheets/application.scss";

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = true
Turbo.setProgressBarDelay(100)

// Stimulus
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

import 'jquery'
import 'bootstrap/dist/js/bootstrap.min'
import feather from "feather-icons"
window.feather = feather
import "@oddcamp/cocoon-vanilla-js";

// components
import './components/mixpanel'

document.addEventListener("turbo:load", function() {
  feather.replace();
});

// スクロール位置がトップにきたらthの背景色を変える
// .scrolled がCSSに必要
// window.addEventListener('scroll', function() {
//   var header = document.querySelector('.sticky-top');
//   var rect = header.getBoundingClientRect();
//   if (rect.top <= 0) {
//     header.classList.add('scrolled');
//   } else {
//     header.classList.remove('scrolled');
//   }
// });
