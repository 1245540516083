import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["select2"];

  connect() {
    this.initializeTomSelect();
  }

  initializeTomSelect() {
    this.select2Targets.forEach((element) => {
      // すでに初期化されている場合は何もしない
      if (element.tomselect) {
        return false;
      }

      let settings = {
        maxOptions: null,
        // itemClass: 'item bg-info text-white',
        render: {
          no_results: function (data, escape) {
            return '<div class="no-results">"' + escape(data.input) + '" は見つかりませんでした</div>';
          },
          // オプションのヘッダーにすべて選択/解除を追加、クリックしたらoptgroup内のオプションをすべて選択/解除する
          optgroup_header: function (data, escape) {
            const optgroupHeader = document.createElement('div');
            optgroupHeader.className = 'optgroup-header';
            optgroupHeader.textContent = escape(data.label) + ' > すべて選択/解除';
            optgroupHeader.addEventListener('click', () => {
              // すべてのオプションを対象に
              for (let value in this.options) {
                const option = this.options[value];
                // オプションがoptgroupに属しているか確認
                if (option.optgroup === data.value) {
                  // オプションがすでに選択されている場合は選択解除
                  if (this.items.includes(value)) {
                    this.removeItem(value);
                  } else {
                    // オプションが選択されていない場合は選択する
                    this.addItem(value);
                  }
                }
              }
            });
            return optgroupHeader;
          },
        },
        plugins: {
          'dropdown_input': {},
        },
      };

      // multipleのときだけcheckbox_optionsとclear_buttonを追加
      if (element.hasAttribute('multiple')) {
        settings['plugins']['checkbox_options'] = {
          'checkedClassNames': ['ts-checked'],
          'uncheckedClassNames': ['ts-unchecked'],
        }
      }

      // select.select2 に .control-all-header があるときだけ、全て選択/解除のリンクを追加
      if (element.classList.contains('control-all-header')) {
        settings.onDropdownOpen = function(dropdown) {
          // 既存のselect-deselect-wrapperを削除
          const existingSelectDeselectWrapper = dropdown.querySelector('.select-deselect-wrapper');
          if (existingSelectDeselectWrapper) {
            existingSelectDeselectWrapper.parentNode.removeChild(existingSelectDeselectWrapper);
          }

          // 新しい全て選択リンクを作成
          const selectAll = document.createElement('div');
          selectAll.className = 'select-all btn-light'; // クラス名を追加
          selectAll.textContent = 'すべて選択';
          selectAll.addEventListener('click', () => {
            // 現在表示されている選択肢を対象に
            const visibleOptions = dropdown.querySelectorAll('.ts-dropdown-content .option');
            visibleOptions.forEach((option) => {
              const value = option.getAttribute('data-value');
              // オプションが選択されていない場合は選択する
              if (!this.items.includes(value)) {
                this.addItem(value);
              }
            });
          });

          // 新しい全て解除リンクを作成
          const deselectAll = document.createElement('div');
          deselectAll.className = 'deselect-all btn-light'; // クラス名を追加
          deselectAll.textContent = 'すべて解除';
          deselectAll.addEventListener('click', () => {
            // 現在表示されている選択肢を対象に
            const visibleOptions = dropdown.querySelectorAll('.ts-dropdown-content .option');
            visibleOptions.forEach((option) => {
              const value = option.getAttribute('data-value');
              // オプションが選択されている場合は選択解除
              if (this.items.includes(value)) {
                this.removeItem(value, true); // 引数にtrueを追加
              }
            });
          });

          // 全て選択と全て解除のリンクを包む新しいdiv要素を作成
          const selectDeselectWrapper = document.createElement('div');
          selectDeselectWrapper.className = 'select-deselect-wrapper';
          selectDeselectWrapper.appendChild(selectAll);
          selectDeselectWrapper.appendChild(deselectAll);

          // 検索用のinputの下に全て選択/解除リンクを追加
          const dropdownInputWrap = dropdown.querySelector('.dropdown-input-wrap');
          dropdownInputWrap.appendChild(selectDeselectWrapper);
        };
      }

      new TomSelect(element, settings);
    });

    // .dropdown-input, 検索窓のplaceholderを設定
    document.querySelectorAll('.select2 .dropdown-input').forEach(function(element) {
      element.setAttribute('placeholder', '検索');
    });
  }
}
