import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.bulkStartAtField = this.element.querySelector('#bulk_start_at');
    this.addSeminarScheduleLink = this.element.querySelector('.add_fields');
    this.element.querySelector('#show-bulk-start-at-input').addEventListener('click', this.showBulkStartAtInput.bind(this));
    this.bulkStartAtField.addEventListener('input', this.handleBulkStartAtInput.bind(this));
    this.handleFreeInput();
  }

  disconnect() {
    // cleanup if necessary
  }

  handleBulkStartAtInput(e) {
    const inputLines = e.target.value.split('\n');

    inputLines.forEach((line) => {
      let [date, time, duration, capacity, location] = line.split('\t');

      // Check if date is valid and convert to UTC
      const dateParts = date.split('/');
      const dateObj = new Date(Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2]));
      if (isNaN(dateObj.getTime())) {
        console.error(`Invalid date: ${date}`);
        return;
      }
      date = dateObj.toISOString().split('T')[0];

      // Check if time is valid
      const timeParts = time ? time.split(':') : ['00', '00'];
      if (timeParts.length !== 2 || isNaN(timeParts[0]) || isNaN(timeParts[1])) {
        console.error(`Invalid time: ${time}`);
        return;
      }
      const hours = timeParts[0].padStart(2, '0');
      const minutes = timeParts[1].padStart(2, '0');
      time = `${hours}:${minutes}`;

      duration = duration || 0;
      capacity = capacity || 0;
      location = location || '';

      // Programmatically click the link to add a new field
      this.addSeminarScheduleLink.click();

      // The new field is the last child of the container
      const fields = document.querySelectorAll('#seminar_schedules .nested-fields');
      const newField = fields[fields.length - 1];

      if (newField) {
        newField.querySelector('.capybara-seminar-start-at').value = `${date}T${time}`;
        newField.querySelector('.capybara-seminar-duration').value = duration;
        newField.querySelector('.capybara-seminar-capacity').value = capacity;
        newField.querySelector('.capybara-seminar-location').value = location;
      }
    });
    // 0.5秒後に入力欄をクリアする
    setTimeout(() => {
      this.bulkStartAtField.value = '';
    }, 500);
  }

  showBulkStartAtInput(e) {
    e.preventDefault();
    this.bulkStartAtField.classList.remove('d-none');
    e.target.classList.add('d-none');
  }

  handleFreeInput() {
    const el = this.element.querySelector('#seminar_schedules');
    if (el === null) return;

    el.addEventListener('click', function (event) {
      if (!event.target.matches('.free-input')) return;
      const check = event.target.checked;
      if (check) {
        event.target.closest('.nested-fields').querySelector('.start-at input').value = '';
        event.target.closest('.nested-fields').querySelector('.start-at input').disabled = true;
      } else {
        event.target.closest('.nested-fields').querySelector('.start-at input').disabled = false;
      }
    });
  }
}
