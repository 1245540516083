import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleOverflow"];

  connect() {
    this.initializeTooltip();
  }

  initializeTooltip() {
    this.toggleOverflowTargets.forEach((element) => {
      element.addEventListener('click', this.toggleOverflow);
    });
  }

  toggleOverflow(event) {
    if (event.currentTarget.classList.contains('max-line-2')) {
      event.currentTarget.classList.remove('max-line-2');
    } else {
      event.currentTarget.classList.add('max-line-2');
    }
  }
}
