import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.setupReloadLinks();
  }

  disconnect() {
    // cleanup if necessary
  }

  setupReloadLinks() {
    const el = document.getElementById('message-statuses-list');
    if (el === null) return false;

    const frame = document.querySelector('#message-statuses-list');
    const links = document.querySelectorAll('.reload-message-statuses');
    links.forEach((link) => {
      link.addEventListener('click', (e) => {
        // e.preventDefault();
        if (frame) {
          Turbo.cache.clear();
          setTimeout(() => {
            if (frame.src) {
              frame.reload();
            } else {
              frame.src = window.location.href;
            }
          }, 3000);
        }
      });
    });
  }
}
