import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', (event) => {
      this.previewImage(event);
      this.disableAttachedImage();
    });
  }

  previewImage(event) {
    const file = event.target.files[0]
    const isOK = file?.type?.startsWith('image/')
    const image = (file && isOK) ? `<img src=${URL.createObjectURL(file)}>` : ''
    const previewImage = event.target.closest('.nested-fields').querySelector('.job-posting-image-preview');
    previewImage.innerHTML = '';
    previewImage.insertAdjacentHTML('afterbegin',image);
  }

  disableAttachedImage() {
    const attachedImage = this.element.closest('.nested-fields').querySelector('.attached-image');
    if (attachedImage) {
      attachedImage.setAttribute("disabled", true);
    }
  }
}
