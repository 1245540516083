import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.scrollDown();
  }

  scrollDown() {
    this.element.scrollTo({top: this.element.scrollHeight, behavior: 'smooth'});
  }
}
